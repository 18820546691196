<template>
  <div class="grid">
      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_brand()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Brands</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.brands }} 
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-fw pi-building text-blue-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_products()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Products</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.products }} 
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-fw pi-shopping-bag text-blue-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_todays_deals()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Todays Deals</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.today_deals }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-copy text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_category()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Categories</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.categories }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-database text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_collection()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Collections</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.collections }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-folder text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_bannner()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Banner</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.banners }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-book text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_contact()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Contacts</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.contacts }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-book text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_slider()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Sliders</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.sliders }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-book text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_product_type()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Products Types</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.product_types }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-book text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-3">
          <div v-on:click="go_to_user()">
              <div class="card mb-0" style="background-color:#BDD7EE">
                  <div class="flex justify-content-between mb-1">
                      <div>
                          <span class="block  font-medium mb-1">Users</span>
                          <div class="text-900 font-medium text-xl">
                              {{ dashcount.users }}
                          </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                          style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-book text-cyan-500 text-xl"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
  <div class="card">
        <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem"  />
    </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import EventBus from '@/AppEventBus';

export default {
data() {
return {
      counts:{},
      isLoadingModel:false,
      fullPage:true,
      user_key: "",
      client_id_dropdown: [],
      client_id_search: '',
      routeitem: '',
      routeitems: [],
      
      pieOptions: null,
      tabs: ['Dashboard', 'Live Ride Tracking Dashboard'],

      selectedTab: "Dashboard",
      pieData: {},
      pieData1: {},
      center: { lat: 19.0626704, lng: 73.005094 },
      center1: {},
      centerstart: {},
      polyline: [
      ],
      polyline1: [
      ],
      polypath: {
         
      },

      search_key: "",
      home: true,
      random: 0,
      token: '',
      get_date: "",
      get_date_2: "",
      locations1: [],
      locations: [
          { lat: 19.0626704, lng: 73.005094, name: "Prathamesh" },
          { lat: 28.38, lng: 77.12, name: "Sanjay" }

      ],
      markers: [
          {
              position: [18.5314, 73.845],
              draggable: true,
              title: "Marker title"
          }]
      ,
      messages: [],
      inputMessageText: "",

      form: {
          roomName: "63a939f2b319a2f01eb75798",
          userName: "639c6caae0aa678e9f6de543",
      },
      products: null,
      todayslectures: [],

      dashcount: {
          "brands": 0,
          "products": 0,
          "today_deals": 0,
          "categories": 0,
          "banners": 0,
          "sliders": 0,
          "contacts": 0,
          "product_types": 0,
          "collections": 0,
          "users": 0,               
      },

      lineData: {
          labels: ['0', '0 to 20', '20 to 40', '40 to 60', '60 to 80', '80 to 100'],
          datasets: [
              {
                  label: 'Network',
                  data: [],
                  backgroundColor: '#00bb7e',

              },
              
          ]
      },
      travellist: [],
      travellist_filter: [],
      lastNumber: 1,
    
      barData: {
          labels: [],
          height: "150px",
          datasets: [
              {
                  label: 'Total Rides',
                  backgroundColor: '#2f4860',
                  data: []
              }
          ]
      },
      barData1: {
          labels: [],
          datasets: [
              {
                  label: 'No Of Lectures by Teacher',
                  backgroundColor: '#2f4860',
                  data: []
              }
          ]
      },
      barOptions: null,
      barOptions1: null,
      items: [
          { label: 'Add New', icon: 'pi pi-fw pi-plus' },
          { label: 'Remove', icon: 'pi pi-fw pi-minus' }
      ],
      lineOptions: null,
    

  }    
},
methods:
{
  async dashboardcount() {
      var data = {};
      var promise = apis.dashboardcount(data);
      promise
          .then(response => {

              this.counts = response.data.data;
              console.log("count data",this.counts);
              this.dashcount.brands = this.counts.total_brands;
              this.dashcount.products = this.counts.total_products;
              this.dashcount.today_deals = this.counts.today_deals;
              this.dashcount.categories = this.counts.categories;
              this.dashcount.banners = this.counts.banners;
              this.dashcount.sliders = this.counts.sliders;
              this.dashcount.contacts = this.counts.contacts;
              this.dashcount.product_types = this.counts.product_types;
              this.dashcount.collections = this.counts.collections;
              this.dashcount.users = this.counts.users;

              console.log("count_array",this.dashcount.brands);
          });
  },
  async dashboardcount2() {
      var data = {};
      var promise = apis.dashboardcount(data);
      promise
          .then(response => {

              var other_data = response.data.data;
              console.log("count data",other_data);
              this.dashcount.today_travels = other_data.todays_travel;

              this.dashcount.travels = other_data.total_travel;
              this.dashcount.schools = other_data.schools;
              this.dashcount.school_buses = other_data.school_buses;
              this.dashcount.alerts = other_data.alerts;
              this.dashcount.today_alerts = other_data.todays_alerts;
              this.dashcount.vehicles = other_data.vehicles;
              this.dashcount.routes=other_data.routes;
              this.dashcount.clients=other_data.clients;

              var data = response.data.data;




              for (var i = 0; i < data.length; i++) {

                  if (data[i].user_type == 'Driver') {
                      this.dashcount.drivers += data[i].count;
                  }
                  if (data[i].user_type == 'Owner') {
                      this.dashcount.owners += data[i].count;
                  }
                  if (data[i].user_type == 'Rider') {
                      this.dashcount.riders += data[i].count;
                  }
                  if (data[i].user_type == 'Driver Owner') {
                      this.dashcount.driverowner = data[i].count;
                  }
                  if (data[i].user_type == 'Admin') {
                      this.dashcount.admin = data[i].count;
                  }

              }
            
              this.dashcount.alerts = response.data.other_data.alerts;
              this.dashcount.today_alerts = response.data.other_data.todays_alerts;
              this.pieData = {
                  labels: response.data.other_data.user_counts.labels,
                  datasets: [
                      {
                          data: response.data.other_data.user_counts.series_data,
                          backgroundColor: [
                              "#F04136",
                              "#2B3890",
                              "#F7941E",
                              "#F9ED32",
                              "#D7F422",
                              "#DE6BBC",
                              "#DEDD59",
                              "#58DEDF"
                          ],
                          hoverBackgroundColor: [
                              "#FF6384",
                              "#36A2EB",
                              "#FFCE56",
                              "#662D91",
                              "#F8F194",
                              "#58DEDF",
                              "#949597",
                              "#F8F194"

                          ]
                      }
                  ]
              };
              this.pieData1 = {
                  labels: response.data.other_data.vehicle_group.labels,
                  datasets: [
                      {
                          data: response.data.other_data.vehicle_group.series_data,
                          backgroundColor: [
                              "#F04136",
                              "#2B3890",
                              "#F7941E",
                              "#39B54A",
                              "#D7F422",
                              "#DE6BBC",
                              "#DEDD59",
                              "#58DEDF"
                          ],
                          hoverBackgroundColor: [
                              "#FF6384",
                              "#36A2EB",
                              "#FFCE56",
                              "#662D91",
                              "#F8F194",
                              "#58DEDF",
                              "#949597",
                              "#DEDD59"
                          ]
                      }
                  ]
              };
        
          });

  },

  go_to_brand()
  {
      this.$router.push('/brandmaster');
  },
  go_to_category()
  {
      this.$router.push('/category');           
  },
  go_to_collection() {
      this.$router.push('/collection');
  },
  go_to_products() {
      this.$router.push('/productmaster');
  },
  go_to_product_type() {
      this.$router.push('/productType');
  },
  go_to_bannner() {
      this.$router.push('/bannermaster');
  },
  go_to_todays_deals() {
      this.$router.push('/todays_deal');
  },
  go_to_user() {
      this.$router.push('/usermaster');
  },
  go_to_contact() {
      this.$router.push('/contact');
  },
  go_to_slider() {
      this.$router.push('/slidermaster');
  },


  async dashboardRideData() {
      var data = { client_id: localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '' };
      var promise = apis.dashboardRideData(data);
      promise
          .then(response => {
              this.barData.labels = response.data.data.series;
              this.barData.datasets[0].data = response.data.data.data;
          });
  },
  get_pending_alert_count() {
var data = {
  id: localStorage.getItem("id"),
  count: true,
  unread: false,
  mobile_number:'',
  status: 0,
  from_date: new Date().toISOString().split('T')[0],
  to_date: new Date().toISOString().split('T')[0],
  type:"ride sos alert",
  client_id: localStorage.getItem("client_id")
};

var promise = apis.sosAlertMaster(data);
promise.then((response) => {

  console.log(response);
  this.dashcount.pending_alerts = response.data.data;
 
});

},
isDarkTheme() {
      return this.$appState.darkTheme === true;
  },
  applyLightTheme() {
      this.barOptions = {
          plugins: {
              legend: {
                  labels: {
                      color: '#495057'
                  }
              },

          },
          scales: {
              x: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
              y: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
          }
      };
      this.barOptions1 = {
          indexAxis: 'y',
          plugins: {
              legend: {
                  labels: {
                      color: '#495057'
                  }
              }
          },
          scales: {
              x: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
              y: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
          }
      };
      this.lineOptions = {
          plugins: {
              legend: {
                  labels: {
                      color: '#495057'
                  }
              }
          },
          scales: {
              x: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
              y: {
                  ticks: {
                      color: '#495057'
                  },
                  grid: {
                      color: '#ebedef',
                  }
              },
          }
      };
  },
  applyDarkTheme() {
      this.lineOptions = {
          plugins: {
              legend: {
                  labels: {
                      color: '#ebedef'
                  }
              }
          },
          scales: {
              x: {
                  ticks: {
                      color: '#ebedef'
                  },
                  grid: {
                      color: 'rgba(160, 167, 181, .3)',
                  }
              },
              y: {
                  ticks: {
                      color: '#ebedef'
                  },
                  grid: {
                      color: 'rgba(160, 167, 181, .3)',
                  }
              },
          }
      };
  }
  
},
async created()
{
 
  await this.dashboardcount();
  await this.get_pending_alert_count();
  await this.dashboardRideData();


},
async mounted() {
  this.themeChangeListener = (event) => {
      if (event.dark)
          this.applyDarkTheme();
      else
          this.applyLightTheme();
  };
  EventBus.on('change-theme', this.themeChangeListener);

  if (this.isDarkTheme()) {
      this.applyDarkTheme();
  }
  else {
      this.applyLightTheme();
  }
},
beforeUnmount() {
  EventBus.off('change-theme', this.themeChangeListener);
},
}
</script>
<style scoped>



.bell{
display:block;
color: #9e9e9e;
-webkit-animation: ring 4s .7s ease-in-out infinite;
-webkit-transform-origin: 50% 4px;
-moz-animation: ring 4s .7s ease-in-out infinite;
-moz-transform-origin: 50% 4px;
animation: ring 4s .2s ease-in-out infinite;
transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
0% { -webkit-transform: rotateZ(0); }
1% { -webkit-transform: rotateZ(30deg); }
3% { -webkit-transform: rotateZ(-28deg); }
5% { -webkit-transform: rotateZ(34deg); }
7% { -webkit-transform: rotateZ(-32deg); }
9% { -webkit-transform: rotateZ(30deg); }
11% { -webkit-transform: rotateZ(-28deg); }
13% { -webkit-transform: rotateZ(26deg); }
15% { -webkit-transform: rotateZ(-24deg); }
17% { -webkit-transform: rotateZ(22deg); }
19% { -webkit-transform: rotateZ(-20deg); }
21% { -webkit-transform: rotateZ(18deg); }
23% { -webkit-transform: rotateZ(-16deg); }
25% { -webkit-transform: rotateZ(14deg); }
27% { -webkit-transform: rotateZ(-12deg); }
29% { -webkit-transform: rotateZ(10deg); }
31% { -webkit-transform: rotateZ(-8deg); }
33% { -webkit-transform: rotateZ(6deg); }
35% { -webkit-transform: rotateZ(-4deg); }
37% { -webkit-transform: rotateZ(2deg); }
39% { -webkit-transform: rotateZ(-1deg); }
41% { -webkit-transform: rotateZ(1deg); }

43% { -webkit-transform: rotateZ(0); }
100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
0% { -moz-transform: rotate(0); }
1% { -moz-transform: rotate(30deg); }
3% { -moz-transform: rotate(-28deg); }
5% { -moz-transform: rotate(34deg); }
7% { -moz-transform: rotate(-32deg); }
9% { -moz-transform: rotate(30deg); }
11% { -moz-transform: rotate(-28deg); }
13% { -moz-transform: rotate(26deg); }
15% { -moz-transform: rotate(-24deg); }
17% { -moz-transform: rotate(22deg); }
19% { -moz-transform: rotate(-20deg); }
21% { -moz-transform: rotate(18deg); }
23% { -moz-transform: rotate(-16deg); }
25% { -moz-transform: rotate(14deg); }
27% { -moz-transform: rotate(-12deg); }
29% { -moz-transform: rotate(10deg); }
31% { -moz-transform: rotate(-8deg); }
33% { -moz-transform: rotate(6deg); }
35% { -moz-transform: rotate(-4deg); }
37% { -moz-transform: rotate(2deg); }
39% { -moz-transform: rotate(-1deg); }
41% { -moz-transform: rotate(1deg); }

43% { -moz-transform: rotate(0); }
100% { -moz-transform: rotate(0); }
}

@keyframes ring {
0% { transform: rotate(0); }
1% { transform: rotate(30deg); }
3% { transform: rotate(-28deg); }
5% { transform: rotate(34deg); }
7% { transform: rotate(-32deg); }
9% { transform: rotate(30deg); }
11% { transform: rotate(-28deg); }
13% { transform: rotate(26deg); }
15% { transform: rotate(-24deg); }
17% { transform: rotate(22deg); }
19% { transform: rotate(-20deg); }
21% { transform: rotate(18deg); }
23% { transform: rotate(-16deg); }
25% { transform: rotate(14deg); }
27% { transform: rotate(-12deg); }
29% { transform: rotate(10deg); }
31% { transform: rotate(-8deg); }
33% { transform: rotate(6deg); }
35% { transform: rotate(-4deg); }
37% { transform: rotate(2deg); }
39% { transform: rotate(-1deg); }
41% { transform: rotate(1deg); }

43% { transform: rotate(0); }
100% { transform: rotate(0); }
}
</style>